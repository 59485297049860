/**
 * Determina si se trata de un entorno de desarrollo o no.
 */
export const IS_DEVELOPMENT =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

/**
 * Base URL de la API.
 */
export const API_URL = IS_DEVELOPMENT
  ? "http://localhost:8765"
  : "https://beta.api.gestion.redcantabrarural.com";

/**
 * Configuración por defecto de una llamada a la API de Fonestar.
 */
export const DEFAULT_REQUEST_CONFIG = {
  method: "GET",
  credentials: "include",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

/**
 * Filtros posibles para las llamadas a la API.
 */
export const EVENTS_URL_FILTERS = {
  calendar: "calendar",
  type: "type",
  start_date: "from",
  end_date: "to",
  centers: "centers",
  page: "page",
  limit: "limit",
};

/**
 * Posibles tipos de eventos.
 * 
 * - `wordpress`: Aquellos creados en el WP.
 * - `groupEvent`: Eventos grupales de los que se es organizador.
 * - `support`: Eventos grupales de los que se es apoyo.
 */
export const EVENTS_TYPE = {
  wordpress: "wordpress",
  groupEvent: "groupEvent",
  support: "support",
};
