import { ErrorMessage } from "@hookform/error-message";

export const TextareaControl = ({
  id,
  label,
  errors,
  inputProps = {},
  labelProps = {},
  wrapperProps = {},
}) => {
  const hasError = errors[id] ? true : false;

  return (
    <div className="mb-2" {...wrapperProps}>
      <label
        htmlFor={id}
        className={`mt-3 h-6 text-xs font-bold uppercase leading-8 ${
          hasError ? "text-rose-800" : "text-rcdr-green-dark"
        }`}
        {...labelProps}
      >
        {label}
      </label>
      <textarea
        id={id}
        aria-invalid={hasError ? "true" : "false"}
        aria-describedby={hasError ? `${id}-help` : ""}
        className={`mt-0 block w-full border-0 border-b-2 border-gray-200 px-2 disabled:cursor-not-allowed disabled:italic disabled:text-neutral-600 focus:ring-0 ${
          hasError ? "focus:border-rose-800" : "focus:border-rcdr-green-dark"
        }`}
        {...inputProps}
      />
      <ErrorMessage
        errors={errors}
        name={id}
        as={
          <span
            id={`${id}-help`}
            role="alert"
            className="text-xs text-rose-800"
          />
        }
      />
    </div>
  );
};
