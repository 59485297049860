export const Badge = ({
  children,
  bg = "bg-emerald-600",
  text = "text-white",
}) => {
  return (
    <span
      className={`inline-flex items-center justify-center rounded px-2 py-1 text-xs font-bold uppercase tracking-wider ${bg} ${text}`}
    >
      {children}
    </span>
  );
};
